import React from "react";
import "../../assets/styles/Fruits.css";
import "../../assets/styles/mediaqueris/FruitsMedia.css";
import { IMAGES } from "../../utils";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

const imgUrls = [
  { img: IMAGES.fruit1 },
  // { img: IMAGES.fruit2 },
  { img: IMAGES.fruit3 },
  // { img: IMAGES.fruit4 },
  { img: IMAGES.fruit5 },
  { img: IMAGES.fruit6 },
  // { img: IMAGES.fruit7 },
  // { img: IMAGES.fruit8 },
  { img: IMAGES.fruit9 },
  { img: IMAGES.fruit10 },
];

function Fruits() {
  return (
    <>
      <section className="fruits-tab-bg">
        <div className="fruits-bg-text">
          <h3>We Grow And Sell</h3> <h3>The Best Fruits</h3>
          {/* <h2>We Are Provide</h2> */}
        </div>
      </section>
      <div className="fruits-content-outer">
        <div className="fruits-content-inner">
          <div className="fruit-title">
            <h3>Fruits</h3>
            <div className="fruit-devider fh-devider"></div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <p className="fruits-content">
                Abhita Agro is an export company. Which is your ultimate
                destination for exporting high quality fruits. We provide you
                with the freshest and most delicious fruits from around the
                world. Our commitment to quality, durability and customer
                satisfaction sets us apart as a leading exporter in the
                industry. We source products directly from farmers and export to
                countries within and outside Maharashtra. Which gives a lot of
                benefits to the farmers. We export our products to Well Pak,
                maintaining quality products and that too at affordable prices.
                We offer a wide variety of fruits to suit the tastes of people
                around the world. We offer a wide variety to please every
                palate, from exotic tropical fruits to timeless favorites. Our
                selection is sure to please whether you're seeking for sweet
                Grapes, Orange, Mango, Chikoo, Pomegranate, Guava, or
                Strawberries.
              </p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12"></div>
          </div>
        </div>
      </div>
      <div className="fh-outer">
        <div className="container">
          <h3 className="fh-title">Fruit And Health</h3>
          <div className="fh-devider"></div>
          <div className="fh-content-wrapper">
            <div className="row">
              <div className="col-sm-12 col-lg-5 col-md-5 col-xs-12">
                <p className="fh-list-title">
                  Why we are your partner for all your fruit import needs by
                  perusing our wide selection of fruits.
                </p>
                <div className="fh-list">
                  <ul>
                    <li>
                      <img src={IMAGES.orangeIcon} alt="icon" />
                      <p>Freshness and Quality</p>
                    </li>
                    <li>
                      <img src={IMAGES.orangeIcon} alt="icon" />{" "}
                      <p> Wide Variety</p>
                    </li>
                    <li>
                      <img src={IMAGES.orangeIcon} alt="icon" />{" "}
                      <p>Global Reach</p>
                    </li>
                    <li>
                      <img src={IMAGES.orangeIcon} alt="icon" />{" "}
                      <p>Sustainable Practices</p>
                    </li>
                    <li>
                      <img src={IMAGES.orangeIcon} alt="icon" />{" "}
                      <p>Customized Solutions</p>
                    </li>
                    <li>
                      <img src={IMAGES.orangeIcon} alt="icon" />{" "}
                      <p>Food Safety</p>
                    </li>
                    <li>
                      <img src={IMAGES.orangeIcon} alt="icon" />{" "}
                      <p>Customer Satisfaction</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 col-md-4 col-xs-12">
                <div className="fruit-rural-wrapper">
                  <div className="fruit-rural">
                    <img src={IMAGES.fruitRural} alt="Fruit rural" />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-3 col-md-3 col-xs-12">
                <div className="fruit-intro fruit-shape">
                  <p>
                    You've heard of Superfoods, but…Superfruits? Not every fruit
                    qualifies. Those deemed ``super`` by nutrition scientists
                    are packed with antioxidants, fiber, vitamins and minerals,
                    and other nutrients that can help you live longer, look
                    better, and even prevent disease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5">
        <div className="container">
          <div className="pt-5">
            <div className="text-center fruit-title-outer">
              <img className="orangeIcon" src={IMAGES.orange} />
              <h3 className="fruit-title">
                <span>Sonala Santri </span>Processing Unit
              </h3>
              <img className="orangeIcon" src={IMAGES.orange} />
            </div>{" "}
            <div className="fh-devider"></div>
            <div className="pb-5">
              <p>
                <strong>
                  Introducing Sonala Oranges by Abhita Agro Industries Pvt Ltd –{" "}
                </strong>
                the best in quality and taste. Our Sonala Oranges are picked at
                the right time, full of natural juice and flavor. We handpick
                and check each orange to meet the highest standards, ensuring
                they are packed with vitamins and minerals. Known for their
                sweet, tangy flavor, Sonala Orangesare perfect for eating fresh,
                making juice, or cooking. We buy directly from farmers,
                supporting them and ensuring honesty. Grown with eco-friendly
                methods, our oranges help the environment and local communities.
                Sent to markets worldwide, Sonala Oranges maintain quality and
                affordability. We follow strict safety rules to keep everything
                clean and hygienic. Our fair trade practices improve farmers'
                lives, and we work hard to make sure customers are happy with
                every purchase. Packed carefully to keep them fresh, Sonala
                Oranges are available all year round. Abhita Agro is known for
                quality and reliability. Enjoy the finest oranges with every
                bite and experience the best of nature's bounty.
              </p>
            </div>
          </div>
          <div className="row">
            <Gallery>
              {imgUrls.map((item, index) => (
                <div className="col-xl-3 col-lg-4 col-md-6  col-sm-12">
                  <Item
                    original={item.img}
                    thumbnail={item.img}
                    width="800"
                    height="600"
                  >
                    {({ ref, open }) => (
                      <div className="media-img-col-wrapper">
                        <div className="media-img-col">
                          <img
                            ref={ref}
                            onClick={open}
                            src={item.img}
                            alt={`Image ${index + 1}`}
                          />
                        </div>
                      </div>
                    )}
                  </Item>
                </div>
              ))}
            </Gallery>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fruits;
